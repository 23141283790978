export const orchardRoute = [    
    { lng: 13.2235184, lat: 52.8740725 },
    { lng: 13.2236364, lat: 52.8739883 },
    { lng: 13.2237813, lat: 52.8738799 },
    { lng: 13.2240576, lat: 52.8739252 },
    { lng: 13.2243258, lat: 52.8739964 },
    { lng: 13.2248622, lat: 52.8744173 },
    { lng: 13.2259136, lat: 52.8752818 },
    { lng: 13.227818, lat: 52.8775546 },
    { lng: 13.2280326, lat: 52.8776582 },
    { lng: 13.247897, lat: 52.8756477 },
    { lng: 13.2491201, lat: 52.8763017 },
    { lng: 13.2522959, lat: 52.8785323 },
    { lng: 13.2561475, lat: 52.877137 },
    { lng: 13.2651329, lat: 52.8718497 },
    { lng: 13.2644945, lat: 52.8711373 },
]

export const trainStationRoute = [
    { lng: 13.2128137, lat: 52.868372 },
    { lng: 13.2124865, lat: 52.8687509 },
    { lng: 13.2141012, lat: 52.8688221 },
    { lng: 13.2153994, lat: 52.8688739 },
    { lng: 13.2162577, lat: 52.868929 },
    { lng: 13.2171696, lat: 52.8689872 },
    { lng: 13.2187897, lat: 52.8695701 },
    { lng: 13.2196856, lat: 52.8700979 },
    { lng: 13.2198089, lat: 52.870765 },
    { lng: 13.2200181, lat: 52.8712086 },
    { lng: 13.2205117, lat: 52.871691 },
    { lng: 13.2209998, lat: 52.8720634 },
    { lng: 13.221547, lat: 52.8724325 },
    { lng: 13.2220834, lat: 52.8726916 },
    { lng: 13.2227647, lat: 52.8729603 },
    { lng: 13.2234138, lat: 52.8731449 },
    { lng: 13.2239449, lat: 52.8735205 },
    { lng: 13.2234889, lat: 52.8738086 },
    { lng: 13.2237732, lat: 52.8738734 },
    { lng: 13.2236177, lat: 52.8739932 },
]